import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Auto Send Setting">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The auto send setting is what makes using Simple Review Management completely effortless.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
This setting is only available to customers who have connected their QuickBooks Online account to their Simple RM account.
    </Message>
    <p>{`When the auto send setting is active, Simple RM monitors your interactions with the customer for an event that signifies your business with the customer has concluded. When one of the events is detected a review request is triggered for the customer.`}</p>
    <p>{`This means you continue to use QuickBooks Online the same way you always have, but your business starts benefiting from the additional reviews generated from sending review requests.`}</p>
    <h4 {...{
      "id": "events-that-trigger-a-review-request-are"
    }}>{`Events that trigger a review request are:`}</h4>
    <ul>
      <li parentName="ul">{`A sales receipt created`}</li>
      <li parentName="ul">{`An invoice is paid in full`}</li>
    </ul>
    <h4 {...{
      "id": "there-are-a-number-of-exceptions-that-prevent-this-event-from-triggering-a-review-request"
    }}>{`There are a number of exceptions that prevent this event from triggering a review request:`}</h4>
    <ul>
      <li parentName="ul">{`The invoice is connected to an estimate that does not have the status closed`}</li>
      <li parentName="ul">{`The invoice memo or one of the line item descriptions on the invoice includes the word “deposit”, “down payment” or “prepayment”`}</li>
      <li parentName="ul">{`The current date is before the last service date on the sales receipt or invoice (a request will be sent once the last service date passes)`}</li>
    </ul>
    <h4 {...{
      "id": "there-are-a-few-additional-reasons-why-a-review-request-might-not-be-sent-to-a-customer-despite-one-of-the-events-occurring"
    }}>{`There are a few additional reasons why a review request might not be sent to a customer despite one of the events occurring:`}</h4>
    <ul>
      <li parentName="ul">{`The email address already received a review request (the same email address can not go through the review requests process more than once)`}</li>
      <li parentName="ul">{`The customer could be missing a contact method (phone number or email address)`}</li>
      <li parentName="ul">{`The customer could be on the “no contact list”`}</li>
      <li parentName="ul">{`Your account could be set up to require the customer to have a first name set to send them a review request and this customer is missing a first name`}</li>
    </ul>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
If you are <Link href="/help-center/triggering-with-a-custom-field" mdxType="Link">triggering requests with  a custom field</Link> note that leaving the Request Review custom field blank will not stop a request from being triggered by the Auto Send setting. The only way to stop a review request from being triggered for a customer by the auto send setting is to add the customer to the "no contact list".
    </Message>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      